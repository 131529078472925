import React, {Component} from 'react'


//Component
class MenuBtn extends Component {

  state = {
    open: false,
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open
    })

    if ( this.props.onClick ) {
      this.props.onClick()  
    }
  }


  render() {

    const btn_classes = [
      'menu-btn',
      ( this.state.open ) ? 'open' : '' 
    ]

    return ( 

      <div className={btn_classes.join(' ')} onClick={() => this.handleClick()}>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
        <span className="menu-dot"></span>
      </div>
   )
  } 
}


//Export
export default MenuBtn