import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Icon from './Icon'


//Component
const SocialMedia = ({ className }) => (

  <StaticQuery

    query={graphql`
      query SocialMediaQuery {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                social_media {
                  url
                  icon {
                    id
                    alt_text
                    localFile {
                      childImageSharp {
                        resize(height: 90) {
                          width
                          height
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}

    render = { data => {
      
      const icons = data.allWordpressAcfOptions.edges[0].node.options.social_media

      return (
        <ul className={ "icons" + ( className ? " " + className : "" ) }>

          {icons.map( (icon,index) => {

            const iconSrc = ( icon.icon && icon.icon.localFile ) ? icon.icon.localFile.childImageSharp.resize.src : null
            const iconHeight = ( icon.icon && icon.icon.localFile ) ? icon.icon.localFile.childImageSharp.resize.height : null
            const iconAlt = ( icon.icon ) ? icon.icon.alt_text : null

            return (
              <Icon 
                key={index}
                to={icon.url}
                target="_blank"
                src={iconSrc}
                iconHeight={iconHeight}
                alt={iconAlt}
                glitch={true}
              />
            )
          })}

        </ul>
      )
    }}
  />
)


//Props validation
SocialMedia.propTypes = {
  className: PropTypes.string
}


//Export
export default SocialMedia