import React from 'react'
import PropTypes from 'prop-types'


//Component
const TextLinks = ({ className, uppercase, content }) => {

  let text = content ? content : ''
  let lowercaseClass = !uppercase ? '--lowercase' : ''

  //Test text for email adresses to wrap with a link
  const mail_regex = /[a-zA-ZÅÄÖåäö–0-9-_.]{2,}[@][a-zA-ZÅÄÖåäö–0-9-_.]{2,}[.][a-zA-ZÅÄÖåäö–0-9-_.]{2,8}/gi

  text = text.replace( mail_regex, function ( match ) { 
    return `<a class="mail strike-through${lowercaseClass}" href="mailto:${match}">${match}</a>`
  })

  //Test contact_details for phone numbers to wrap with a link
  const phone_regex = /[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{2,}[ ][0-9]{2,}|[+0-9]{2,5}[-– ]{1,3}[0-9]{5,}|[+0-9]{5,}/gi

  text = text.replace(phone_regex, function ( match ) {
    return `<a class="phone strike-through${lowercaseClass}" href="tel:${match}">${match}</a>`
  })

  return (
    <div className={className} dangerouslySetInnerHTML={{__html: text }} />
  )
}


//Props validation
TextLinks.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  uppercase: PropTypes.bool
}


//Export
export default TextLinks