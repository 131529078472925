import React from 'react'
import PropTypes from 'prop-types'


//Component
const Text = ({ className, two_cols, full_width, lg_text, children }) => {

  let classes = [
    'text-container',
    ( className ) ? className : null,
    ( lg_text ) ? 'size-lg-text' : null,
    ( two_cols ) ? 'two-cols' : null,
    ( full_width ) ? 'full-width' : null
  ]

  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  )
}


//Props validation
Text.propTypes = {
  className: PropTypes.string,
  two_cols: PropTypes.bool,
  full_width: PropTypes.bool,
}


//Export
export default Text