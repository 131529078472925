import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
import LinkDynamic from './LinkDynamic'
import MenuMain from './MenuMain'
import { imageLoadedStatus } from '../misc/utility.js'

//Component
class Header extends Component {

  state = {
    scrolled: false,
    pos: 0,
    prev_pos: 0,
    break_pos: 0,
  }

  handleScroll = () => {

    //console.log('Scroll', window.pageYOffset);

    if ( typeof window !== 'undefined' ) {
      const scroll_top = ( window.pageYOffset ) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
      const scrolled = ( scroll_top > 0 ) ? true : false
      const prev_pos = this.state.pos
      const break_pos = ( scroll_top < this.state.pos ) ? this.state.break_pos : scroll_top

      this.setState({
        scrolled: scrolled,
        pos: scroll_top,
        prev_pos: prev_pos,
        break_pos: break_pos
      })
    }
  }

  handleScrollbar = (e) => {
    const scroll_top = e.top
    const scrolled = ( scroll_top > 0 ) ? true : false
    const prev_pos = this.state.pos
    const break_pos = ( scroll_top < this.state.pos ) ? this.state.break_pos : scroll_top

    this.setState({
      scrolled: scrolled,
      pos: e.top,
      prev_pos: prev_pos,
      break_pos: break_pos
    })
  }

  handleFullpage = (e) => {
    
    //console.log('Fullpage', e);

    const pageTop = ( window.matchMedia("(max-width: 767px)").matches ) ? 10 : 0
    const scrolled = ( e.destination.index > pageTop ) ? true : false
    this.setState({
      scrolled: scrolled,
      pos: 0,
      prev_pos: 0,
      break_pos: 0
    })
  }

  handlePWAPrompt = (e) => {
    //console.log('handlePWAPrompt')
    //e.preventDefault()
  }

  componentDidMount() {
    if ( typeof window !== 'undefined' ) {
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('scrollbar', this.handleScrollbar)
      window.addEventListener('fullpagescroll', this.handleFullpage)
      window.addEventListener('beforeinstallprompt', this.handlePWAPrompt)
    }
  }

  componentWillUnmount() {
    if ( typeof window !== 'undefined' ) {
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('scrollbar', this.handleScrollbar)
      window.removeEventListener('fullpagescroll', this.handleFullpage)
      window.removeEventListener('beforeinstallprompt', this.handlePWAPrompt)
    }
  }

  render () {

    const {firstSection, children} = this.props
    const {scrolled, pos, break_pos} = this.state

    return (
      <StaticQuery 

        query={graphql`
          query HeaderQuery {
            allWordpressAcfOptions {
              edges {
                node {
                  options {
                    logo {
                      alt_text
                      localFile {
                        childImageSharp {
                          fixed(height: 30) {
                            ...Fixed
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}

        render = {data => {
          
          const logo = data.allWordpressAcfOptions.edges[0].node.options.logo

          let header_classes = [
            'header'
          ]

          if ( firstSection === 'AnimationIntro' || firstSection === 'WordPressAcf_AnimationIntro' ) {
            header_classes.push('animationIntro')
          }

          if (scrolled) {
            header_classes.push('scrolled')
            
          } else {
            header_classes.push('not-scrolled')

          }

          if ( (pos + 400) < break_pos) {
            header_classes.push('scrolled-up')

          }

          const logoFixed = ( logo && logo.localFile ) ? logo.localFile.childImageSharp.fixed : null
          const logoAlt = ( logo && logo.alt_text ) ? logo.alt_text : null

          //Image console
          imageLoadedStatus('Header.js', (!!logoFixed), logoAlt)

          return (
            <header className={header_classes.join(' ')}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 d-flex">

                      { logoFixed ? (
                        <div className="logo-container">
                          <LinkDynamic to="/">
                            <Img className="logo" fixed={logoFixed} alt={logoAlt} />
                          </LinkDynamic>
                        </div>
                      ) : null}

                      {children}
                      
                      <MenuMain className="main-nav" />

                    </div>
                  </div>
              </div>
            </header>
          )
        }}
      />
    )
  }
}


//Export
export default Header