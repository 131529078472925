import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'

import MenuBtn from './MenuBtn'
import MenuMainItem from './MenuMainItem'


//Component
class MenuMain extends Component {

  state = {
    menu_open: false,
    sub_menu_open: false
  }

  handleMenu = () => {
    this.setState({
      menu_open: !this.state.menu_open
    })
  }

  handleSubMenu = (open) => {
    this.setState({
      sub_menu_open: open
    })
  }

  render() {

    const { className } = this.props

    return ( 

      <StaticQuery

        query={graphql`
          query MenuMainQuery {
            wordpressWpApiMenusMenusItems {
              items {
                title
                url
                target
                object_slug
                classes
              }
            }
          }
        `}

        render = {data => {
          
          const menu_items = data.wordpressWpApiMenusMenusItems.items

          const list_container_classes = [
            'nav__list__container'
          ]

          if ( this.state.menu_open ) {
            list_container_classes.push('open')
          }

          if ( this.state.sub_menu_open ) {
            list_container_classes.push('open-sub-menu')
          }

          return (
            <nav className={`nav ${className}`}>

              <MenuBtn 
                onClick={() => this.handleMenu()}
              />

              <div className={list_container_classes.join(' ')}>
                <ul className="nav__list">

                  {menu_items.map( (node , index) => {

                    return (
                      <MenuMainItem 
                        key={index} 
                        item={node} 
                        index={index}
                        open={this.state.sub_menu_open}
                        handleSubMenu={(open) => this.handleSubMenu(open)}
                      />
                    )
                      
                  })}
                  
                </ul>
              </div>
            </nav>
          )
        }}
      />
    )
  }
}


//Export
export default MenuMain