import React from 'react'
import PropTypes from 'prop-types'

import LinkDynamic from './LinkDynamic'

//Component
const LinkBtn = ({ title, to, className, ...other }) => {

  let classes = [
    'link',
    'link--btn'
  ]
  if ( className ) {
    classes.push(className)
  }

  return (
    <React.Fragment>
      {( to ) ? (

          <LinkDynamic 
            to={to}
            className={classes.join(' ')}
            {...other}
          >
            {title}
          </LinkDynamic>

      ) : (

          <span
            className={classes.join(' ')}
            {...other}
          >
            {title}
          </span>

      )}
    </React.Fragment>
  )
}


//Props validation
LinkBtn.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}


//Export
export default LinkBtn