import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { imageLoadedStatus } from '../misc/utility.js'


//Component
const ImageHover = ({hoverStyle, darkFilter, className, style, imgStyle, ...other}) => {

  const container_classes = [
    'image-hover-container',
    ( darkFilter ) ? 'dark-filter' : '',
    ( hoverStyle ) ? 'hover hover--'+hoverStyle : '',
    ( className ) ? className : '' 
  ]

  let container_style = (style ) ? JSON.parse(JSON.stringify(style)) : {}
  container_style.position = 'relative'
  container_style.overflow = 'hidden'

  let wrapper_style = (style) ? JSON.parse(JSON.stringify(style)) : {}
  let image_style = (imgStyle) ? JSON.parse(JSON.stringify(imgStyle)) : {}

  if ( hoverStyle === 'zoom' ) {
    image_style.top = '50%'
    image_style.left = '50%'
    image_style.transform = 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)'
  }

  if (hoverStyle === 'right') {
    wrapper_style.width = ( wrapper_style.width ) ? (parseInt(wrapper_style.width)*1.03)+'%' : '103%';

  }

  //Image console
  let imageSrc = 'imageSource'
  if ( 'fixed' in other ) {
    imageSrc = (!!(other.fixed && other.fixed.src))

  } else if ( 'fluid' in other )  {
    imageSrc = (!!(other.fluid && other.fluid.src))

  }
  imageLoadedStatus('ImageHover.js', imageSrc, other.alt_text)


  return (
    <div className={container_classes.join(' ')}>
      <div className="image-hover-container" style={container_style}>
        {( (('fixed' in other) && other.fixed !== null ) || (('fluid' in other) && other.fluid !== null ) ) ? (
          <Img style={wrapper_style} imgStyle={image_style} {...other} />
        ) : null}
      </div>
    </div>
  )
}


//Props validation
ImageHover.propTypes = {
  className: PropTypes.string
}


//Export
export default ImageHover