import React from 'react'
import PropTypes from 'prop-types'

import LinkDynamic from './LinkDynamic'
import { imageLoadedStatus } from '../misc/utility.js'


//Component
const Icon = ({ iconKey, className, to, target, src, alt, iconHeight, glitch }) => {

  if ( !src ) {
    return (
      <React.Fragment />
    )
  }

  const height = iconHeight ? {height: iconHeight/2 } : ''

  //Image console
  imageLoadedStatus('Icon.js', (!!src), alt)

  if ( to ) {

    return (
      <li className={ "icon" + ( className ? " " + className : "" ) }>
        
        <LinkDynamic
          to={to}
          target={target}
        >

          <div className={ glitch ? "img-container glitch" : "img-container"} style={height}>

            {glitch ? <img src={src} className="glitch before" alt="Glitch effect before" /> : ''}          

            <img src={src} alt={alt} />

            {glitch ? <img src={src} className="glitch after" alt="Glitch effect after" /> : ''}

          </div>
                
        </LinkDynamic>

      </li>
    )

  } else {

    return (
      <li key={iconKey} className={ "icon" + ( className ? " " + className : "" ) }>
    
        <div className="img-container" style={height}>
          <img src={src} alt={alt} />
        </div>

      </li>
    )

  }
}


//Props validation
Icon.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  iconHeight: PropTypes.number,
  glitch: PropTypes.bool
}


//Export
export default Icon