import React from 'react'
import PropTypes from 'prop-types'

import LinkDynamic from './LinkDynamic'

//Component
const LinkCTA = ({ title, to, className, background, cleanStyle, ...other }) => {

  let containerClasses = [
    'link--cta__container',
    ( cleanStyle ) ? 'mb-0' : null
  ]

  let classes = [
    'link',
    'link--cta',
    ( className ) ? className : null,
    ( background ) ? 'fill-color' : null,
    ( cleanStyle ) ? 'clean-style' : null
  ]

  return (
    <div className={containerClasses.join(' ')}>
      <LinkDynamic 
        to={to}
        className={classes.join(' ')}
        {...other}
      >
        <span className="before" dangerouslySetInnerHTML={{__html: title }} />
        <span className="display" dangerouslySetInnerHTML={{__html: title }} />
        <span className="after" dangerouslySetInnerHTML={{__html: title }} />
      </LinkDynamic>
    </div>
  )
}


//Props validation
LinkCTA.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  background: PropTypes.bool
}


//Export
export default LinkCTA