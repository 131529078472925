import React, {Component} from 'react'

import LinkDynamic from './LinkDynamic'


//Component
class MenuMainItem extends Component {

  state = {
    sub_menu_open: false
  }

  UNSAFE_componentWillReceiveProps ({open}) {
    if(!open) {
      this.setState({
        sub_menu_open: false
      })
    }
  }

  handleSubMenu = (open) => {
    this.setState({
      sub_menu_open: open
    })

    this.props.handleSubMenu(open)
  }

  render() {

    const {item/*, index*/} = this.props
    //const formattedNumber = ("0"+(index+1)).slice(-2)

    let item_classes = [
      'nav__item'
    ]

    let sub_menu_classes = [
      'nav__list__sub'
    ]

    if (this.state.sub_menu_open) {
      item_classes.push('open')
      sub_menu_classes.push('open')
    }

    return (
 
      <li className={item_classes.join(' ')}>
        
        { !item.wordpress_children ? (

          <LinkDynamic
            to={item.url}
            target={item.target}
            className={item.classes}
            activeClassName='current'
          >
            {item.title}
          </LinkDynamic>

        ) : (

          <span onClick={() => this.handleSubMenu(true)}>
            {item.title}
          </span>

        )} 

        {/*  item.wordpress_children is not in use for site version 1.0
          item.wordpress_children ? (
          <ul className={sub_menu_classes.join(' ')}>

            <li className="nav__item go-back" onClick={() => this.handleSubMenu(false)}>
              <span dangerouslySetInnerHTML={{__html: '< Tilllbaka' }}/>
            </li>

            {item.wordpress_children.map( (sub_item, index) => {

              return (
                <li key={index} className="nav__item">

                  <LinkDynamic
                    to={sub_item.url}
                    target={sub_item.target}
                    className={sub_item.classes}
                    activeClassName='current'
                  >
                    {sub_item.title}
                  </LinkDynamic>

                </li>
              )

            })}

          </ul>
        ) : null */}

      </li>
    )
  }
}


//Export
export default MenuMainItem