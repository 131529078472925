import React, {Component} from 'react'
//import PropTypes from 'prop-types'

//Component
class MenuCategory extends Component {

  state = {
    current: (this.props.current) ? this.props.current : 'all'
  }

  handleClick = ( cat ) => {
    this.setState({
      current: cat
    })

    this.props.onChange( cat )
  }

  render() {
    
    const {className, items, allTitle} = this.props
    const all_title = allTitle ? allTitle : 'Allt';
    
    let classes = [
      'nav',
      'list-nav'
    ]

    if (className) {
      classes.push(className)
    }

    return (
      <nav className={classes.join(' ')}>
        <ul className="nav__list">

          <li className="nav__item">

            <span 
              className={this.state.current === 'all' ? 'current' : null} 
              onClick={() => this.handleClick('all')}
            >
              {all_title}
            </span>

          </li>

          {items ? items.map( (node , index) => {

            const item = ( node.node ) ? node.node : node

            return (
              <li key={index} className="nav__item">

                <span 
                  className={this.state.current === item.slug ? 'current' : null} 
                  onClick={() => this.handleClick(item.slug)}
                >
                  {item.name}
                </span>

              </li>
            )
              
          }) : null}
          
        </ul>
      </nav>
    )
  }
}


//Props validation
/*Layout.propTypes = {
  children: PropTypes.node.isRequired,
}*/



//Export
export default MenuCategory