import React, {Component} from 'react'
//import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import ReactFullpage from '@fullpage/react-fullpage/dist/react-fullpage-commonjs'


if ( typeof global.navigator === 'undefined' ) {
	global.navigator = {
	  userAgent: 'node.js'
	}
}


class FullpageWrapper extends Component {

  componentDidMount() {
		document.fullpagescroll = new CustomEvent('fullpagescroll', { 
			detail: 	"Triggers when fullpage.js changes section",
			bubbles: 	true
		})
  }

  componentWillUnmount() {
  	clearTimeout(this.scrollTimeout)
  }

  state = {
  	interval: 0,
    allowScroll: true
  }

  setScrollTimeout = () => {
  	if ( typeof document !== 'undefined' && window.matchMedia("(min-width: 768px)").matches ) {

  		clearTimeout(this.scrollTimeout)
  		document.fullpageApi.setAllowScrolling( false )

  		this.scrollTimeout = setTimeout(function(){ 
  			document.fullpageApi.setAllowScrolling( true )
  		}, 1200)
  	}
  }

  render() {

  	const sections = this.props.children

 		let tooltips = ( sections[0].props.content ) ? sections[0].props.content.map((node, index) => {
       return '<span onclick="fullpage_api.moveTo('+(index+1)+')"><span class="section__num">'+('0'+(index+1)).slice(-2)+'</span><span class="section__name">// '+node.section_id+'</span></span>'
    }) : []

    tooltips.push( '<span onclick="fullpage_api.moveTo('+(tooltips.length+1)+')"><span class="section__num">'+('0'+(tooltips.length+1)).slice(-2)+'</span><span class="section__name">// Outro</span></span>' )

    return (
      <ReactFullpage
      	licenseKey="D154C10D-26774ED9-98FB51F3-DDE248C0"
	      navigation={true}
	      resize={true}
	      recordHistory={false}
	      navigationTooltips={tooltips}
				showActiveTooltip={true}
				scrollingSpeed={800}
				responsiveWidth="768"
				
				onLeave={(origin, destination, direction) => {
					document.fullpagescroll.origin = origin
					document.fullpagescroll.destination = destination
					document.fullpagescroll.direction = direction
					document.dispatchEvent( document.fullpagescroll )

					this.setScrollTimeout()
				}}

        render={({ state, fullpageApi }) => {

        	if ( typeof document !== 'undefined' ) {
        		document.fullpageApi = fullpageApi
        	}

          return (
            <ReactFullpage.Wrapper>

		          {sections}

			      </ReactFullpage.Wrapper>
          )
        }}
      />
    )
  }
}


//Export
export default FullpageWrapper