import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ImageHover from './ImageHover'
import LinkDynamic from './LinkDynamic'


//Component
class ArchiveItem extends Component {
  
  state = {
    hover: false
  }

  handleHover = (hover) => this.setState(
    { 
      hover: hover 
    }
  )

  render() {

    let { title, label, link, fixed, className, darkFilter, titleOnlyItem, ...other } = this.props

    const containerClasses = [
      'archive-item',
      ( titleOnlyItem ) ? 'title-only' : null,
      ( className ) ? className : null
    ]

    const imageClassName = ( this.state.hover ) ? 'trigger-hover' : null 

    function heading () {
      
      const heading = ( titleOnlyItem ) ? title : label
      const HeadingSize = ( titleOnlyItem ) ? 'h2' : 'h3'

      if ( heading ) {
        return <HeadingSize className="size-h2-heading heading font-main" dangerouslySetInnerHTML={{__html: heading }} />
        
      } else {
        return null

      }
    }

    function subHeading () {

      const subHeading = ( titleOnlyItem ) ? null : title

      if ( title && !titleOnlyItem) {
        return <h2 className="size-h4-heading heading font-main" dangerouslySetInnerHTML={{__html: subHeading }} />
        
      } else {
        return null
      }
    }

    return (
      <LinkDynamic 
        to={link} 
        className={containerClasses.join(' ')} 
        onMouseEnter={() => this.handleHover(true)}
        onMouseLeave={() => this.handleHover(false)}
        {...other}
      >

        <ImageHover 
          fixed={fixed} 
          hoverStyle="zoom"
          darkFilter={darkFilter}
          className={imageClassName}
          style={{
            position: 'absolute',
            top: 0,
            width: '100%', 
            height: 0, 
            paddingBottom: '66%'
          }}
          imgStyle={{
            width: '100%', 
            height: '100%'
          }}
        />

        <div className="archive-item__inner">

          {heading()}

          {subHeading()}

        </div>
      </LinkDynamic>
    )
  }
}


//Props validation
ArchiveItem.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default ArchiveItem