import React, {Component} from 'react'

//Component
class CloseBtn extends Component {

  handleClose = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {

    let { color, size, className } = this.props

    const classes = [
      'btn--close',
      ( color ) ? 'btn--close--'+color : 'btn--close--purple',
      ( size === 'large' ) ? 'btn--close--large' : null,
      ( className ) ? className : null
    ]

    return(
      <button className={classes.join(' ')} onClick={() => this.handleClose()}>
        Close
      </button>
    )
  }
}


//Export
export default CloseBtn