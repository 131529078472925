import React, {Component} from 'react'
import { Link } from 'gatsby'

//Component
class LinkDynamic extends Component {

  handleHashLink = (e) => {
    /*
     * This function handles links with hash-links
     * and scrolls the page to the selected element/section
     */

    const currentTemplate = (typeof document !== 'undefined') ? document.currentTemplate : null
    const to = this.props.to

    //Use react-custom-scrollbars to trigger scroll
    if ( currentTemplate === 'scrollbar' ) {

      //console.log( 'Linktype: scrollbar' )
      //console.log( window.scrollbars )
      console.log( 'Hashlinks only works for fullpage layouts' )

    } else if ( currentTemplate === 'fullpage' ) {

      //Use default scrollbar to trigger scroll
      if ( typeof window !== 'undefined' && window.matchMedia("(max-width: 767px)").matches ) {

        //console.log( 'Linktype: default' )
        console.log( 'Hashlinks only works for fullpage layouts' )

      //Use default react-fullpage to trigger scroll
      } else if ( typeof document !== 'undefined' ) {

        e.preventDefault()
        const preparedTo = to.replace('#','').toLowerCase().replace(['ä','å'],'a').replace('ö','o')
        document.fullpageApi.moveTo(preparedTo)

      }
    }
  }

  render() {

    const { children, to, ...other } = this.props

    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)
    const hash = /^#/.test(to)

    // Use gatsby-link for internal links
    if (internal) {
      return (

        <Link 
          to={to} 
          getProps={({ isPartiallyCurrent }) => {
              return isPartiallyCurrent ? { className: other.className+' current' } : null
            }
          }
          {...other}
        >
          {children}
        </Link>
      )

    //ScrollTo if hash
    } else if ( hash ) {

      return (
        <a href={to} onClick={(e) => this.handleHashLink(e)} {...other}>
          {children}
        </a>
      )

    //Regular link
    } else {

      return (
        <a href={to} rel="noopener" {...other}>
          {children}
        </a>
      )

    }
  }
}


//Export
export default LinkDynamic