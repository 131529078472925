import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { setServer, getServer, bodyOverflow } from '../misc/utility.js'

import CloseBtn from './CloseBtn'

//Component
class LightboxModal extends Component {

  state = {
    type:         'content',
    showLightbox: false,
    fadeIn:       0,
    content:      (<div>Innehållet gick inte att visa</div>),
    imageID:      null,
    galleryID:    null
  }

  componentDidMount = () => {
    window.addEventListener('keyup', this.handleKeyUp, false)

    let _server = getServer()
    _server.lightboxModal = this
    setServer( _server )
  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
    window.removeEventListener('keyup', this.handleKeyUp, false)
    bodyOverflow( 'visible' )
  }

  closeModal = () => {
    this.setState({ 
      showLightbox: false,
      fadeIn: 0
    }, () => {

      bodyOverflow( 'visible' )
      clearInterval(this.timer)

    })
  }

  openModal = ( args ) => {

    const defaults = {
      type:       'content', //content || gallery
      content:    this.state.content,
      imageID:    null,
      galleryID:  null
    }

    const vars = {...defaults, ...args, showLightbox: true }

    this.setState( vars , () => {

      bodyOverflow( 'hidden' )
      this.fadeIn()
    })
  }

  handleKeyUp = e => {
    e.preventDefault()

    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        this.goBack()
      }
      if (keyCode === 39) {
        // Right Arrow Key
        this.goForward()
      }
      if (keyCode === 27) {
        // Escape key
        this.closeModal()
      }
    }
  }

  goBack = () => {
    let { type, galleryID, imageID } = this.state

    if ( type === 'gallery' ) {

      const _server = getServer()
      const { lightboxGalleries } = _server

      const currentGallery = lightboxGalleries[galleryID]
      const galleryKeys = Object.keys( currentGallery )
      const galleryQuantity = galleryKeys.length
      const currentIndex = galleryKeys.indexOf( imageID ) - 1

      const newImageID = ( currentIndex >= 0 ) ? galleryKeys[currentIndex] : galleryKeys[galleryQuantity - 1]

      this.setState({ 
        imageID: newImageID 
      }, () => {
        this.fadeIn()
      })
    }
  }

  goForward = () => {
    let { type, galleryID, imageID } = this.state

    if ( type === 'gallery' ) {

      const _server = getServer()
      const { lightboxGalleries } = _server

      const currentGallery = lightboxGalleries[galleryID]
      const galleryKeys = Object.keys( currentGallery )
      const galleryQuantity = galleryKeys.length
      const currentIndex = galleryKeys.indexOf( imageID ) + 1

      const newImageID = ( currentIndex < galleryQuantity ) ? galleryKeys[currentIndex] : galleryKeys[0]

      this.setState({ 
        imageID: newImageID 
      }, () => {
        this.fadeIn()
      })
    }
  }

  fadeIn = () => {
    const startTime = Date.now()
    clearInterval(this.timer)

    this.setState({
      fadeIn: 0,
    })

    this.timer = setInterval(() => {

      if ( this.state.fadeIn < 1 ) {
        this.setState({
          fadeIn: (Date.now() - startTime)/100,
        })

      } else {
        clearInterval(this.timer)
        this.setState({
          fadeIn: 1,
        })

      }
    })
  }

  render() {

    const _server = getServer()

    let thisImage = null
    let currentIndex = 0
    let galleryQuantity = 0
    let showGalleryControlls = false
    const { type, showLightbox, imageID, galleryID, content } = this.state
    const { lightboxGalleries } = _server

    if ( type === 'gallery' && lightboxGalleries && lightboxGalleries[galleryID] ) {

      const currentGallery = lightboxGalleries[galleryID]
      const galleryKeys = Object.keys( currentGallery )

      galleryQuantity = galleryKeys.length
      showGalleryControlls = galleryQuantity > 1
      currentIndex = galleryKeys.indexOf( imageID ) + 1
      thisImage = currentGallery[imageID]
    }

    const lightBoxClasses = [
      'lightbox-modal',
      ( showLightbox ) ? 'show-modal' : null
    ]

    return (
      <div className={lightBoxClasses.join(' ')}>

        <div className="lightbox-overlay" onClick={this.closeModal}></div>

        <div className="lightbox-content">

          { (showGalleryControlls && thisImage) ? (
            <div className="lightbox-counter">
              {currentIndex}/{galleryQuantity}
            </div>
          ) : null }

          { (type === 'gallery' && thisImage) ? (

            <img 
              className="lightbox-image" 
              alt={thisImage.altText ? thisImage.altText : ''} 
              src={thisImage.fluid && thisImage.fluid.originalImg ? thisImage.fluid.originalImg : ''} 
              style={{
                opacity: this.state.fadeIn
              }}/>

          ) : content }

          <div className="lightbox-controls close">

            <CloseBtn onClick={() => this.closeModal()} />
            
          </div>

          { (showGalleryControlls && thisImage) ? (
            <div className="lightbox-controls navigation">
              <button className="prev" onClick={this.goBack}>
                Previous
              </button>

              <button className="next" onClick={this.goForward}>
                Next
              </button>
            </div>
          ) : null }

        </div>

      </div>
    )
  }
}


//Props validation
LightboxModal.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  background: PropTypes.bool
}


//Export
export default LightboxModal