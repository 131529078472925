import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import getScrollbarWidth from 'react-custom-scrollbars/lib/utils/getScrollbarWidth.js'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'
import DisclaimerCookie from '../components/DisclaimerCookie'
//import DisclaimerBrowser from '../components/DisclaimerBrowser'
import FullpageWrapper from '../components/FullpageWrapper'
import LightboxModal from '../components/LightboxModal'

import '../assets/style/main.scss'

//Component
class Layout extends Component {
  
  componentDidMount() {
    document.scrollbar = new CustomEvent('scrollbar', { 
      detail:   "Triggers when react-component scrollbar scrolls",
      bubbles:  true
    })

    document.currentTemplate = ( this.props.fullpage ) ? 'fullpage' : 'scrollbar'
  }

  renderThumb = ({ style, ...props }) => {

    let thumbStyle = {
      borderRadius    : 2,
      zIndex          : '200',
      backgroundColor : 'rgba(93, 93, 93, 0.5)',
    }

    return (
      <div {...props} style={{...style, ...thumbStyle}}/>
    )
  }

  handleScroll = ( values ) => {
    if ( typeof document !== 'undefined' ) {
      document.scrollbar.top = values.scrollTop
      document.scrollbar.left = values.scrollLeft
      document.dispatchEvent( document.scrollbar )
    }
  }

  handleWrapperLoad = ( main ) => {
    if ( main && !this.props.fullpage ) {

      //Trigger a update for react-custom-scrollbars 
      //to prevent native scrollbar to be visible

      const overflowEle = main.children[0].children[0]
      const scrollbarWidth = getScrollbarWidth()

      overflowEle.style.marginRight = '-'+scrollbarWidth+'px'
      overflowEle.style.marginBottom = '-'+scrollbarWidth+'px'

    }
  }

  render () {

    const {current_meta, children, fullpage, absoluteFooter, className} = this.props
    const first_section = ( children.props && children.props.content && children.props.content[0] ) ? children.props.content[0].__typename : null

    let desktop = ( typeof window !== 'undefined' && window.matchMedia("(min-width: 768px)").matches ) ? true : false
    
    let main_classes = [
      'main',
      'fill-height',
      ( className) ? className : null,
      ( fullpage && desktop ) ? 'main-fullpage' : 'main-default'
    ]

    let meta_props = []

    if ( current_meta && current_meta.meta_title ) {
      meta_props.meta_title = current_meta.meta_title
    }

    if ( current_meta && current_meta.meta_desc ) {
      meta_props.meta_desc = current_meta.meta_desc
    }

    if ( current_meta && current_meta.meta_canonical ) {
      meta_props.meta_canonical = current_meta.meta_canonical
    }

    return (
      <Fragment>
        <Head {...meta_props} />
        <Header firstSection={first_section} />

        <main ref={this.handleWrapperLoad} className={main_classes.join(' ')}>

          { (fullpage && desktop) || (fullpage && typeof window === 'undefined') ? (

            <FullpageWrapper>

              {children}
              <Footer fullpage={true} />

            </FullpageWrapper>

          ) : (

            <Scrollbars
              ref={(r) => window.scrollbars = r}
              style={{ width: '100vw', height: '100vh' }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={300}
              renderThumbHorizontal={this.renderThumb}
              renderThumbVertical={this.renderThumb}
              onScrollFrame={(values) => this.handleScroll(values)}
            >

              {children}
              <Footer 
                absolute={absoluteFooter}
              />

            </Scrollbars>

          )}

        </main>

        <DisclaimerCookie />
        {/*<DisclaimerBrowser />*/}
        
        <LightboxModal />

      </Fragment>
    )
  }
}


//Props validation
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


//Export
export default Layout