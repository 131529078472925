import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'


//Component
const Head = ( props ) => {

  const { meta_title, meta_desc } = props
  const meta = []

  if ( meta_desc ) {
    meta.push({ 
      name: 'description', 
      content: meta_desc
    })
  }

  return (
    <Helmet
      title = {meta_title}
      meta = {meta}
    >
      <script type="text/javascript" />
      <html lang="sv" />
      <link rel="shortcut icon" href="/static/favicon-48x48.png"/>
      <link rel="apple-touch-icon" sizes="48x48" href="/static/favicon-48x48.png"/>
      <link rel="apple-touch-icon" sizes="72x72" href="/static/favicon-72x72.png"/>
      <link rel="apple-touch-icon" sizes="96x96" href="/static/favicon-96x96.png"/>
      <link rel="apple-touch-icon" sizes="144x144" href="/static/favicon-144x144.png"/>
      <link rel="apple-touch-icon" sizes="192x192" href="/static/favicon-192x192.png"/>
      <link rel="apple-touch-icon" sizes="256x256" href="/static/favicon-256x256.png"/>
      <link rel="apple-touch-icon" sizes="384x384" href="/static/favicon-384x384.png"/>
      <link rel="apple-touch-icon" sizes="512x512" href="/static/favicon-512x512.png"/>

      <link rel="preconnect" href="https://px.ads.linkedin.com" />
      <link rel="preconnect" href="https://snap.licdn.com" />
      <link rel="preconnect" href="https://www.google-analytics.com" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />
    </Helmet>
  )
}


//Props validation
Head.propTypes = {
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  meta_canonical: PropTypes.string,
}


//Export
export default Head