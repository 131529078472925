import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { setCookie, getCookie } from '../misc/utility.js'

import Text from './Text'
import TextLinks from './TextLinks'
import LinkBtn from './LinkBtn'
import CloseBtn from './CloseBtn'


//Component
class DisclaimerCookie extends Component {

  componentDidMount() {
    let acceptedCookies = getCookie('acceptedCookies')

    if ( !acceptedCookies ) {
      this.setState({
        disclaimerOut: false,
        disclaimerHide: false
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.transition)
  }

  state = {
    disclaimerOut: true,
    disclaimerHide: true
  }

  handleSeen = () => {

    setCookie('acceptedCookies', true, 730)

    this.setState({
      disclaimerOut: true
    }, () => {

      this.transition = setTimeout(() => { 

        this.setState({
          disclaimerHide: true
        })
      }, 1000)

    })

  }

  render() {

    return(
      <StaticQuery 

        query={graphql`
          query CookieQuery {
            allWordpressAcfOptions {
              edges {
                node {
                  options {
                    cookie_text
                    cookie_link {
                      title
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        `}

        render = {data => {

          const { cookie_text, cookie_link } = data.allWordpressAcfOptions.edges[0].node.options

          const classes = [
            'disclaimer__cookie',
            (this.state.disclaimerOut) ? 'transition-out' : null
          ]

          if ( this.state.disclaimerHide ) {

            return null

          } else {

            return (
              <section className={classes.join(' ')}>
                <div className="container-fluid">
                  <div className="row justify-content-between py-1">

                    <div className="col-12 col-md-7 col-lg-8 col-xxl-9 d-flex align-items-center">

                      {cookie_text ? (
                        <Text full_width={true} >
                          <TextLinks content={cookie_text}/>
                        </Text>
                      ) : null}

                    </div>

                    <div className="col-12 col-md-5 col-lg-4 col-xxl-3 d-flex align-items-center justify-content-between justify-content-md-end mt-1 mt-md-0">

                      {cookie_link ? (
                        <LinkBtn to={cookie_link.url} title={cookie_link.title} className={'link--btn--small link--btn--black'} onClick={this.handleSeen}/>
                      ) : null}

                      <CloseBtn color="black" onClick={() => this.handleSeen()}/>

                    </div>

                  </div>
                </div>
              </section>
            )

          }
        }}
      />
    )
  }
}


//Export
export default DisclaimerCookie