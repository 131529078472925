
var _server = {};
export function setServer(server) { _server = server; }
export function getServer() { return _server; }


export function imageLoadedStatus( componentName, localfileStatus, altText ) {
	/*
	 * Used in the build process to show local image status
	 */
	if ( process && process.env && process.env.NODE_ENV ) {
		if ( process.env.NODE_ENV === 'production' ) {
			if ( typeof window === 'undefined' ) {	
				console.log( '---------------------' )
			  console.log( componentName )
			  console.log('Alt: '+altText)
			  console.log('Local: '+(localfileStatus))
			  console.log( '---------------------' )
			}
		}
	}
}

export function bodyOverflow( status ) {
	if ( typeof document !== 'undefined' ) {
		const html = document.getElementsByTagName('html')[0]
		const body = document.getElementsByTagName('body')[0]
		let allowScrolling = true

		if ( status === 'hidden' ) {
			html.style.overflow = 'hidden'
			body.style.overflow = 'hidden'
			allowScrolling = false
			
		} else {
			html.style.overflow = 'visible'
			body.style.overflow = 'visible'
		}

		if ( document.fullpageApi ) {
			document.fullpageApi.setAllowScrolling(allowScrolling)
		}
	}
}

export function hideFullpageNav( status ) {
	if ( typeof document !== 'undefined' ) {
		const fpNav = document.getElementById('fp-nav')

		if ( fpNav ) {

			if ( status === 'hidden' ) {
				fpNav.style.visibility = 'hidden'
				fpNav.style.opacity = 0
				
			} else {
				fpNav.style.visibility = 'visible'
				fpNav.style.opacity = 1
			}

		}
	}
}


export function setCookie(name, value, days) {
	if ( typeof document !== 'undefined' ) {

	  let expires = ""

	  if ( days ) {
      let date = new Date()
      date.setTime(date.getTime() + (days*24*60*60*1000))
      expires = "; expires=" + date.toUTCString()
	  }

	  document.cookie = name + "=" + (value || "")  + expires + "; path=/"

	}
}

export function getCookie(name) {
	if ( typeof document !== 'undefined' ) {

		let nameEQ = name + "="
		let ca = document.cookie.split(';')

		for( let i=0; i < ca.length; i++ ) {

	    let c = ca[i]
	    while ( c.charAt(0) === ' ') c = c.substring(1, c.length)
	    if ( c.indexOf(nameEQ) === 0 ) return c.substring(nameEQ.length, c.length)

		}

		return null
	}
}

export function eraseCookie(name) {  
	if ( typeof document !== 'undefined' ) {

	  document.cookie = name+'=; Max-Age=-99999999;'
	} 
}