import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SocialMedia from './SocialMedia'
import LinkCTA from './LinkCTA'
import Pattern from '../assets/images/pattern_footer.svg'


//Component
const Footer = ({ fullpage, absolute, children }) => {

  const prop_key = ( fullpage ) ? 'data-anchor' : 'id'

  return (
    <StaticQuery

      query={graphql`
        query FooterQuery {
          allWordpressAcfOptions {
            edges {
              node {
                options {
                  social_media {
                    icon {
                      id
                    }
                  }
                  link_to_work {
                    title
                    url
                    target
                  }
                  link_to_contact {
                    title
                    url
                    target
                  }
                }
              }
            }
          }
        }
      `}

      render = {data => {
        
        const options = data.allWordpressAcfOptions.edges[0].node.options

        const footerClasses = [
          'footer',
          'fp-auto-height',
          'section',
          ( !absolute ) ? 'py-3 mt-4 mt-md-1' : 'pb-1',
          ( !absolute ) ? 'py-md-4' : null,
          ( absolute ) ? 'absolute-footer' : null
        ]

        let dynamicProp = {}
        dynamicProp[prop_key] = 'outro'

        dynamicProp['style'] = ( !absolute ) ? {
          backgroundImage: `url(${Pattern})`,
          backgroundSize: '80px auto',
          backgroundPosition: 'center top'
        } : null

        return (
          <footer className={footerClasses.join(' ')} {...dynamicProp}>
            <div className="container-fluid">
              <div className="row footer__row justify-content-between">

                {options.link_to_work ? (
                  <div className="d-flex col-12 col-md-4 align-items-md-center my-1 my-md-3">
                    
                    <LinkCTA
                        to={options.link_to_work.url}
                        target={options.link_to_work.target}
                        title={options.link_to_work.title}
                      />

                  </div>
                ) : ('')}

                {options.social_media[0].icon.id ? (
                  <div className={"d-flex col-12 col-md-4 align-items-md-center my-md-3"+(( !absolute ) ? " my-2" : null)}>
                    
                    <SocialMedia />

                  </div>
                ) : ('')}

                {options.link_to_contact ? (
                  <div className="d-flex col-12 col-md-4 align-items-md-center my-1 my-md-3">
                    <div className="clearfix">
                      
                      <LinkCTA
                        to={options.link_to_contact.url}
                        target={options.link_to_contact.target}
                        title={options.link_to_contact.title}
                      />
                  
                    </div>
                  </div>
                ) : ('')}

              </div>
            </div>
          </footer>
        )
      }}
    />
  )
}


//Export
export default Footer